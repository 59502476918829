<template>
    <div class="classicCase-container-wrapper">
        <div class="classic-case-bg">
            <img src="../../assets/images/jingpin-bg.png" alt="">
        </div>
        <div class="tab-classification">
            <div class="tab-item" :class="{current:currentClassicId === classicItem.id}"
                 v-for="(classicItem,classicIndex) in classicTab" :key="classicIndex.id"
                 @click="chooseClassic(classicIndex)">{{classicItem.name}}
            </div>
        </div>
        <div class="classic-case-box">
            <div style="width: 1200px;margin: 0 auto">
                <div class="tab-school" v-if="currentClassicId!==4">
                    <div class="tab-item" :class="{current:currentSchoolId === schoolItem.id}"
                         v-for="(schoolItem,schoolIndex) in schoolTab" :key="schoolIndex.id"
                         @click="chooseSchool(schoolIndex)">{{schoolItem.name}}
                    </div>
                </div>
                <div class="classic-case-info" v-if="classicList.length > 0">
                    <div class="top-classic-case" v-for="(item,index) in classicList" :key="`classicList_${index}`">
                        <div class="info-title">
                            <div class="articles-img" @click="viewDetail(item)">
                                <img :src="item.cover_path" alt="">
                            </div>
                            <p class="school-name">{{item.name}}</p>
                            <i class="iconfont school-detail" @click="viewDetail(item)">&#xe639;</i>
                        </div>
                    </div>
                </div>
                <div class="null-data" v-else>
                    <img src="../../assets/images/null-data.png" alt="">
                </div>
                <el-pagination
                        class="pages-center"
                        :current-page="classicPages.currentPageNum"
                        :page-size="classicPages.eachPageNum"
                        layout="prev, pager, next, jumper"
                        :total="classicPages.total"
                        v-if="classicList.length > 0"
                        @current-change="classicPagesCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {getCaseList, getClassification, getSchooltype, oneCase} from '@/utils/apis'

    export default {
        name: "classicCase",
        data() {
            return {
                currentClassicId: 0,
                classicTab: [],
                schoolTab:[],
                schoolTabAll: [],
                currentSchoolId: 0,
                classicList: [],
                classicListAll: [],
                //分页
                classicPages: {
                    currentPageNum: 1,
                    eachPageNum: 12,
                    total:0
                },
            }
        },
        mounted() {
            this.getClassificationList()
            this.getSchoolTypeList()
            // this.viewsList();
        },
        methods: {
            // 分类
            chooseClassic(classicIndex) {
                if (this.classicTab[classicIndex].id !== this.currentClassicId) {
                    this.currentClassicId = this.classicTab[classicIndex].id;
                    switch (classicIndex) {
                        case 0:
                            localStorage.setItem('adminActive', '/case/index');
                            this.$router.push('/case/index');
                            break;
                        case 1:
                            localStorage.setItem('adminActive', '/case/index/enterprise');
                            this.$router.push('/case/index/enterprise');
                            break;
                        case 2:
                            localStorage.setItem('adminActive', '/case/index/modernism');
                            this.$router.push('/case/index/modernism');
                            break;
                        case 3:
                            localStorage.setItem('adminActive', '/case/index/skills');
                            this.$router.push('/case/index/skills');
                            break;
                        default:
                            localStorage.setItem('adminActive', '/case/index');
                            this.$router.push('/case/index');
                            break;
                    }
                    for (let i in this.schoolTabAll){
                        console.log(this.schoolTabAll[i])
                    }
                }
            },
            // 获取案例分类
            getClassificationList() {
                getClassification().then((res) => {
                    this.classicTab = res.data;
                    if (localStorage.getItem('activeIndex')) {
                        switch (localStorage.getItem('activeIndex')) {
                            case '/case/index':
                                this.currentClassicId = this.classicTab[0].id;
                                break;
                            case '/case/index/enterprise':
                                this.currentClassicId = this.classicTab[1].id;
                                break;
                            case '/case/index/modernism':
                                this.currentClassicId = this.classicTab[2].id;
                                break;
                            case '/case/index/skills':
                                this.currentClassicId = this.classicTab[3].id;
                                break;
                            default:
                                this.currentClassicId = this.classicTab[0].id;
                                break;
                        }
                        this.viewsList()
                    } else {
                        this.currentClassicId = this.classicTab[0].id;
                    }
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            //学校
            chooseSchool(schoolIndex) {
                this.currentSchoolId = this.schoolTab[schoolIndex].id;
                // this.viewsList(this.schoolTab[schoolIndex]);
                let arr = [];
                if (schoolIndex) {
                    for (let i in this.classicListAll) {
                        if (this.classicListAll[i].school_type_id === this.currentSchoolId) {
                            arr.push(this.classicListAll[i])
                        }
                    }
                    this.classicList = arr;
                } else {
                    this.classicList = this.classicListAll;
                }
                if (this.$route.query.id) {
                    this.$router.push({query: {}});
                }
            },
            // 获取学校分类
            getSchoolTypeList() {
                getSchooltype().then((res) => {
                    this.schoolTabAll = res.data;
                    let ob = {
                        id: 0,
                        name: '全部'
                    }
                    this.schoolTab = this.schoolTabAll;
                    this.schoolTab.unshift(ob);
                    // this.currentSchoolId = this.schoolTab[0].id;
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            //列表数据
            viewsList() {
                let params = {
                    paging: 1,
                    page: this.classicPages.currentPageNum,
                    pageSize: this.classicPages.eachPageNum,
                    classification_id:this.currentClassicId
                }
                getCaseList(params).then((res) => {
                    this.classicListAll = res.data.list;
                    if (this.$route.query.id) {
                        this.getCaseById(this.$route.query.id)
                    } else {
                        this.classicList = this.classicListAll;
                        this.classicPages.total = res.data.total;
                    }
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            // 获取单个案例详情
            getCaseById(id) {
                // let formData = new FormData();
                // formData.append("id", id);
                let formData = {
                    id: id
                }
                oneCase(formData).then(res => {
                    this.currentClassicId = res.data.classification_id;
                    this.currentSchoolId = res.data.school_type_id;
                    // this.classicList = res.data.content;
                    let arr = [];
                    for (let i in this.classicListAll) {
                        if (this.classicListAll[i].school_type_id === this.currentSchoolId) {
                            arr.push(this.classicListAll[i])
                        }
                    }
                    this.classicList = arr;
                }).catch(err => {
                    console.error(err)
                })
            },
            // 查看详情
            viewDetail(item) {
                this.$router.push({
                    path: '/case/index/detail',
                    query: {
                        id: item.id
                    }
                })
            },
            //分页
            classicPagesCurrentChange(val) {
                this.classicPages.currentPageNum = val;
                this.viewsList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .classicCase-container-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .classic-case-bg {
            width: 100%;
            height: 466px;
            img {
                max-width: 100%;
                height: 466px;
            }
        }
        .tab-classification {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 89px;
            background: #fff;
            box-shadow: 0 0 12px 1px rgba(62, 58, 234, 0.1);
            z-index: 10;
            .tab-item {
                line-height: 32px;
                padding: 0 10px;
                color: #222;
                font-size: 18px;
                cursor: pointer;
                margin-right: 140px;
                position: relative;
                &.current, &:hover {
                    color: #584EEE;
                }
                &.current:nth-child(1):before {
                    content: '';
                    position: absolute;
                    top: 56px;
                    left: 60px;
                    width: 40px;
                    height: 4px;
                    background: #584EEE;
                    border-radius: 4px 4px 0 0;
                }
                &.current:before {
                    content: '';
                    position: absolute;
                    top: 56px;
                    left: 26px;
                    width: 40px;
                    height: 4px;
                    background: #584EEE;
                    border-radius: 4px 4px 0 0;
                }
                &.current:nth-child(3):before {
                    content: '';
                    position: absolute;
                    top: 56px;
                    left: 36px;
                    width: 40px;
                    height: 4px;
                    background: #584EEE;
                    border-radius: 4px 4px 0 0;
                }
            }
        }
        .classic-case-box {
            background: url("../../assets/images/jingpin-bg.jpg") no-repeat center;
            background-size: cover;
        }
        .tab-school {
            display: flex;
            align-items: center;
            padding: 40px 0 20px;
            .tab-item {
                width: 80px;
                line-height: 36px;
                padding: 0 10px;
                color: #444;
                font-size: 18px;
                cursor: pointer;
                margin-right: 30px;
                border-radius: 6px;
                background: #E8EBF0;
                text-align: center;
                position: relative;
                &.current, &:hover {
                    color: #fff;
                    background: #F36F21;
                }
            }
        }
        .classic-case-info {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            .top-classic-case {
                float: left;
                margin-right: 110px;
                width: 320px;
                text-align: center;
                position: relative;
                padding-bottom: 28px;
                height: 275px;
                background: #fff;
                border: 1px solid #fff;
                box-shadow: 0 0 12px 1px rgba(62, 58, 234, 0.1);
                border-radius: 6px;
                margin-bottom: 60px;
                .info-title {
                    cursor: pointer;
                    position: absolute;
                    float: left;
                    top: 10px;
                    left: 22px;
                    padding-bottom: 28px;
                    border-bottom: 1px solid #e6e6e6;
                    transition: .1s;
                    .articles-img {
                        width: 280px;
                        overflow: hidden;
                        height: 200px;
                        img {
                            display: inline-block;
                            width: 280px;

                        }
                    }
                    p {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        text-decoration: none;
                    }
                    .school-name {
                        color: #333;
                        font-size: 18px;
                        height: 18px;
                        line-height: 18px;
                        padding: 24px 10px 13px 10px;
                        text-align: left;
                    }
                    .school-detail {
                        position: absolute;
                        right: 0;
                        bottom: 38px;
                        font-size: 20px;
                        color: #b9b9b9;
                    }
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:hover {
                    top: -10px;
                }
            }
        }
    }
</style>